import styled from 'styled-components';

export const HomeWrapper = styled.section`
    width: 100%;
    margin: 0 auto;    
`

export const NavbarWrapper = styled.div`
    background-color: #2D0060;
    padding-bottom: 50px;

    .btn-primary {
        color: white !important;

        &:focus {
            color: white !important;
        }
    }

    a {
        color: white;
    }
`

export const DocumentacionWrapper = styled.section`
    width: 100%;
    background-color: #2D0060;
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    padding-top: 60px;
    position: relative;
    border-bottom: 1px solid white;
`

export const DocumentacionTexts = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    row-gap: 16px;
    padding: 44px 55px 55px;

    p {
        color: white;
        font-weight: 300;

        @media screen and (max-width: 475px){
            font-size: 14px;
        }
    }

    h2 {
        color: white;

        @media screen and (max-width: 475px){
            font-size: 32px;
        }
    }

    @media screen and (max-width: 1440px){
        max-width: 800px;
    }

    @media screen and (max-width: 992px){
        padding: 22px 25px 25px;
        max-width: 300px;
    }
`

export const DocumentacionButton = styled.button`
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 15px 35px;
    gap: 8px;
    background: #FFFFFF;
    border-radius: 32px;
    border: none;
    cursor: pointer;

    a {
        text-decoration: none;
        color: #2D0060;
        font-weight: 600;
        font-size: 18px;
        line-height: 17px;
        font-family: 'ManropeBody2';
    }

    @media screen and (max-width: 992px){
        a {
            font-size: 14px;
        }
    }
`

export const DocumentacionLinks = styled.div`
    padding: 0px 55px 55px;
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    p {
        font-size: 20px;
        line-height: 28px;
        color: white;
    }

    @media screen and (max-width: 992px){
        padding: 0px 25px 25px;
        max-width: 300px;
    }
`

export const DocumentacionCouples = styled.div`
    display: flex;
    flex-direction: column;

    a {
        color: #E0B81F !important;
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
        font-family: 'ManropeMedium';
        text-decoration: underline !important;
    }

    p {
        color: white;
        font-size: 18px;
        font-weight: 24px;
    }
`

export const DocumentacionImage = styled.img`
    position: absolute;
    width: 100%;
    max-width: 880px;
    right: 0;
    bottom: 0;

    @media screen and (max-width: 1440px){
        max-width: 600px;
    }

    @media screen and (max-width: 992px){
        position: relative;
        max-width: 320px;
    }
`
export const ButtonsWrapper = styled.div`
display: flex;
margin-top: 40px;
gap: 40px;
flex-direction: row;
@media screen and (max-width: 891px){  
    flex-direction: column;
}
`