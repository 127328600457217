import React, { useState, useContext, useEffect } from "react";
import {
  LogoWrapper,
  Phase,
  PhaseTitle1,
  BulletList,
  TimelineContainer,
  Line,
  Bullet,
  ArrowBullet,
  BulletDate,
  LogoLink,
  TitleContainer,
  TitleText,
  BulletInfo,
  StyledFooterWrapper,
  RoadmapTexts
} from "./style";
import Logo from "../assets/images/logowhitebig.svg";
import Logo2 from '../assets/images/logowhite.png';
import 'react-toastify/dist/ReactToastify.css';
import { RoadmapWrapper } from "./style";
import { PhaseSubtitle, BulletItem, BulletText, GlobalStyle } from "./component";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { Label, Title2 } from "../style/globalstyle";
import { Text } from "../../containers/Language";


const Roadmap = () => {

    return (
        <>
        <Navbar logo={Logo2} documentation={'black'} svgcolor={'white'} selectcolor={'white'} burgerColor={'white'} burgerLinksColor={'black'} burgerLinesColor={'white'} />
        <GlobalStyle/>
        <RoadmapWrapper>
            <RoadmapTexts>
                <Label><Text tid={"roadmapTab"} /></Label>
                <Title2><Text tid={"roadmapTitle"} /></Title2>
            </RoadmapTexts>
            <TimelineContainer>
                <Line />
                <Bullet>
                        <BulletDate>
                            <BulletText tid={"roadmapBulletText"}/>
                        </BulletDate>
                        <BulletInfo>
                            <Phase>
                                <PhaseTitle1>Lepton</PhaseTitle1>
                                <PhaseSubtitle tid={"roadmapSubtitle1"}/>
                                <BulletList>
                                    <BulletItem tid={"roadmapBullet1"}/>
                                    <BulletItem tid={"roadmapBullet2"}/>
                                    <BulletItem tid={"roadmapBullet3"}/>
                                    <BulletItem tid={"roadmapBullet4"}/>
                                </BulletList>
                            </Phase>
                        </BulletInfo>
                </Bullet>
                <Bullet>
                    <BulletDate>
                        <BulletText tid={"roadmapBulletText2"}/>
                    </BulletDate>
                    <BulletInfo>
                        <Phase>
                            <PhaseTitle1>Boson</PhaseTitle1>
                            <PhaseSubtitle tid={"roadmapSubtitle2"}/>
                            <BulletList>
                                <BulletItem tid={"roadmapBullet5"}/>
                                <BulletItem tid={"roadmapBullet6"}/>
                                <BulletItem tid={"roadmapBullet7"}/>
                            </BulletList>
                        </Phase>
                    </BulletInfo>
                </Bullet>
                <Bullet>
                    <BulletDate>
                        <BulletText tid={"roadmapBulletText3"}/>
                    </BulletDate>
                    <BulletInfo>
                    <Phase>
                        <PhaseTitle1>Fermion</PhaseTitle1>
                        <PhaseSubtitle tid={"roadmapSubtitle3"}/>
                        <BulletList>
                            <BulletItem tid={"roadmapBullet8"}/>
                            <BulletItem tid={"roadmapBullet9"}/>
                            <BulletItem tid={"roadmapBullet10"}/>
                        </BulletList>
                    </Phase>
                    </BulletInfo>
                </Bullet>
                <Bullet>
                    <BulletDate>
                        <BulletText tid={"roadmapBulletText4"}/>
                    </BulletDate>
                    <BulletInfo>
                    <Phase>
                        <PhaseTitle1>Hadron</PhaseTitle1>
                        <PhaseSubtitle tid={"roadmapSubtitle4"}/>
                        <BulletList>
                            <BulletItem tid={"roadmapBullet11"}/>
                            <BulletItem tid={"roadmapBullet12"}/>
                            <BulletItem tid={"roadmapBullet13"}/>
                            <BulletItem tid={"roadmapBullet14"}/>
                        </BulletList>
                    </Phase>
                    </BulletInfo>
                </Bullet>
                <ArrowBullet></ArrowBullet>
            </TimelineContainer>
        </RoadmapWrapper>
        <StyledFooterWrapper>
            <Footer/>
        </StyledFooterWrapper>
        </>
    );
}

export default Roadmap;